<template>
  <div>
    <div v-if="traitData.traitDetailList.length === 1">
      <div class="trait-info mb-3 mt-3"> 
        <div class="title-header">
          <h4>
            Your Information
          </h4>
        </div>
        <hr />
        <div class="trait-info-body">
          <div class="trait-info-marker">{{traitData.traitDetailList[0].trait.marker}}</div>
          <div class="trait-info-genotype">
            <span :class="{ 'lab-text-sunrise': traitData.traitDetailList[0].trait.mutantAllele === traitData.traitDetailList[0].trait.genotype[0]}">{{traitData.traitDetailList[0].trait.genotype[0]}}</span>
            <trait-genotype-icon />
            <span :class="{ 'lab-text-sunrise': traitData.traitDetailList[0].trait.mutantAllele === traitData.traitDetailList[0].trait.genotype[1]}">{{traitData.traitDetailList[0].trait.genotype[1]}}</span>
          </div>
        </div>
      </div>

      <b-row cols="mb-2 justify-content-md-center">
        <b-col cols="12" xl="6" md="6">
          <div class="trait-card">
            <div class="title-header">
              <h4>
                Frequency
              </h4>
            </div>
            <hr />
            <div class="trait-card-content">
              <p>
                Across world
              </p>
              <div class="trait-frequency-item" v-for="item in traitData.traitDetailList[0].frequencyList" :key="item.id">
                <div class="trait-frequency-header">
                    <div class="trait-frequency-pop">
                      {{item.traitPopulation.populationName}}
                    </div>
                    <div class="trait-frequency-value lab-text-sunrise">
                      {{ item.frequencyRatio }}%
                    </div>
                  </div>

                  <div class="trait-frequency-bar">
                    <div class="frequency-progress">
                      <div class="pop-progress">
                        <div class="pop-progress-fill" :style="{ width: item.frequencyRatio + '%' }"> </div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" xl="6" md="6">
          <div class="title-header">
            <h4>
              Frequency
            </h4>
          </div>
          <hr />
          <div class="trait-card-content" v-if="modernChart">
            <p>
              Among illustrativeDNA customers
            </p>
            <vue-apex-charts
              type="radialBar"
              height="200"
              :options="modernChart.chartOptions"
              :series="modernChart.series"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-if="traitData.traitDetailList.length >= 2">
      <div class="trait-info mb-3 mt-3"> 
        <div class="title-header">
          <h4>
            Your Information
          </h4>
        </div>
        <hr />

        <div class="row">
          <div class="col-md-6 mb-2" v-for="frequencyItem in traitData.traitDetailList" :key="frequencyItem.id">
            <div class="trait-info-body">
              <div class="trait-info-marker">{{frequencyItem.trait.marker}}</div>
              <div class="trait-info-genotype">
                <span :class="{ 'lab-text-sunrise': frequencyItem.trait.mutantAllele === frequencyItem.trait.genotype[0]}">{{frequencyItem.trait.genotype[0]}}</span>
                <trait-genotype-icon />
                <span :class="{ 'lab-text-sunrise': frequencyItem.trait.mutantAllele === frequencyItem.trait.genotype[1]}">{{frequencyItem.trait.genotype[1]}}</span>
              </div>
            </div>
            <div class="trait-card mt-2">
              <div class="trait-card-content">
                <p>
                  Frequency Across world
                </p>
                <div class="trait-frequency-item" v-for="item in frequencyItem.frequencyList" :key="item.id">
                  <div class="trait-frequency-header">
                    <div class="trait-frequency-pop">
                      {{item.traitPopulation.populationName}}
                    </div>
                    <div class="trait-frequency-value lab-text-sunrise">
                      {{ item.frequencyRatio }}%
                    </div>
                  </div>

                  <div class="trait-frequency-bar">
                    <div class="frequency-progress">
                      <div class="pop-progress">
                        <div class="pop-progress-fill" :style="{ width: item.frequencyRatio + '%' }"> </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <b-row cols="mb-2 justify-content-md-center">
        <b-col cols="12" xl="12" md="12">
          <div class="title-header">
            <h4>
              Frequency
            </h4>
          </div>
          <hr />
          <div class="trait-card-content" v-if="modernChart">
            <p>
              Among illustrativeDNA customers
            </p>
            <vue-apex-charts
              type="radialBar"
              height="200"
              :options="modernChart.chartOptions"
              :series="modernChart.series"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import TraitGenotypeIcon from './TraitGenotypeIcon.vue';

export default {
  components: {
    VueApexCharts,
    TraitGenotypeIcon,
  },
  props: {
    traitData: {
      type: Object,
      default: () => {},
    },
    modernChart: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.trait-info {
  .trait-info-body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    .trait-info-marker {
      font-size: 1.4rem;
      font-weight: bold;
      color: var(--lab-fight-sunrise-color);
    }
    .trait-info-genotype {
      font-size: 1.4rem;
      font-weight: bold;
      color: var(--lab-carbon-fiber);
      span {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
}

.dark-layout {
  .trait-info .trait-info-body  .trait-info-genotype {
    color: var(--lab-white);
  }
}

.trait-frequency-item {
  margin-bottom: 1rem;

  .trait-frequency-left {
    width: 70%;
  }

  .trait-frequency-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .trait-frequency-pop {
      font-size: 0.9rem;
      font-weight: bold;
      margin-bottom: 0.2rem;
    }

    .trait-frequency-value {
      border-radius: 10px;
      font-size: 0.85rem;
      font-weight: bold;
      color: var(--lab-text-color-title-02);
    }
  }
  
  .pop-progress {
    margin-top: 10px;
    height: 10px;
    border-radius: 15px;
    background-color: rgba(247, 106, 140, 0.1);

    .pop-progress-fill {
      height: 10px;
      border-radius: 15px;
      background-color: var(--lab-fight-sunrise-color);;
    }
  }
}

</style>
