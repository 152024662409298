<template>
  <div  v-if="traitData">
    <div class="trait-info mb-3 mt-3"> 
      <div class="title-header">
        <h4>
          Your Feedback
        </h4>
      </div>
      <hr />
      <div class="trait-info-body">
        <div class="survey-question">How accurate is this trait for you?</div>
        <div class="survey-answer">
          <div class="survey-answer-item" 
            v-for="traitSurveyItem in traitSurveyTypes" :key="traitSurveyItem.surveyValueType"
            @click="saveTraitSurvey(traitSurveyItem.surveyValueType)"
            :class="{'active': traitSurvey.surveyValueType == traitSurveyItem.surveyValueType}"
            v-show="traitSurveyItem.surveyValueType == traitSurvey.surveyValueType || !traitSurvey.surveyValueType">
            {{traitSurveyItem.label}}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// Alert
import AlertService from '@/common/alert.service'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'

export default {
  components: {
  },
  props: {
    traitData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      traitSurvey: {},
      traitSurveyTypes: [
        {
          surveyValueType: 0,
          label: 'Low',
        },
        {
          surveyValueType: 1,
          label: 'Neutral',
        },
        {
          surveyValueType: 2,
          label: 'High',
        },
      ],
    }
  },
  methods: {
    saveTraitSurvey(type) {
      const query = {
        orderResultTraitHealthId: this.selectedOrderResult.id,
        traitId: this.traitData.trait.id,
        surveyValueType: type,
        description: '',
      };

      this.$store.dispatch('traitsSurvey/saveTraitSurvey', query)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            });
          } else {
            AlertService.warning(this, response.message)
          }

          this.fetchTraitSurvey();
        });
    },

    fetchTraitSurvey() {
      const query = {
        orderResultTraitHealthId: this.selectedOrderResult.id,
        traitId: this.traitData.trait.id,
      };

      this.$store.dispatch('traitsSurvey/fetchTraitSurvey', query)
        .then(response => {
          if (response.statusCode === 200) {
            this.traitSurvey = response.result;
          }
        });
    },
  },
  computed: {
    selectedOrderResult() {
      return this.$store.getters['orderResultTrait/getSelectedOrderResult'];
    },
  },
  mounted() {
    this.fetchTraitSurvey();
  },
}
</script>

<style lang="scss" scoped>
.survey-answer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .survey-answer-item {
    padding: 0.7rem;
    background-color: var(--lab-light-house);
    border-radius: 50%;
    border: 1px solid var(--lab-hr-color);
    cursor: pointer;
    color: var(--lab-stieglitz-silver);
    font-size: 0.85rem;
    font-weight: bold;
  }

  .survey-answer-item:hover {
    background-color: var(--lab-coronation);
  }

  .survey-answer-item.active {
    background-color: var(--lab-night-sky);
    color: var(--lab-white)
  }
}

.dark-layout .survey-answer .survey-answer-item {
  background-color: var(--lab-black);
  color: var(--lab-white);
}

.dark-layout .survey-answer .survey-answer-item:hover {
  background-color: var(--lab-carbon-fiber);
}
</style>
