<template>
  <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100.67 115">
    <defs>
    </defs>
    <g id="Layer_2-2" data-name="Layer 2">
      <g>
        <path class="cls-2" d="M74.07,28.21V9.01c0-4.97-4.03-9.01-9.01-9.01s-9.01,4.03-9.01,9.01v23.44c0,3.95-3.2,7.16-7.16,7.16l-6.42,10.74,6.42,10.74c3.95,0,7.16,3.2,7.16,7.16v37.76c0,4.97,4.03,9.01,9.01,9.01s9.01-4.03,9.01-9.01v-33.52c0-5.05-1.78-9.93-5.02-13.8l-6.99-8.33,6.99-8.33c3.24-3.87,5.02-8.75,5.02-13.8h0Z"/>
        <path class="cls-3" d="M41.74,32.45V9.01c0-4.97-4.03-9.01-9.01-9.01s-9.01,4.03-9.01,9.01V28.21c0,5.05,1.78,9.93,5.02,13.8l6.99,8.33-6.99,8.33c-3.24,3.87-5.02,8.75-5.02,13.8v33.52c0,4.97,4.03,9.01,9.01,9.01s9.01-4.03,9.01-9.01v-37.76c0-3.95,3.2-7.16,7.16-7.16v-21.47c-3.95,0-7.16-3.2-7.16-7.16Z"/>
      </g>
      <line class="cls-1" x1="72.67" y1="50.34" x2="100.67" y2="50.34"/>
      <line class="cls-1" x1="28" y1="51.16" y2="51.16"/>
    </g>
  </svg>
</template>

<script>
export default {
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
}
</script>

<style lang="css" scoped>  
svg {
  height: 120px;
  margin-top: 10px;
}      
.cls-1 {
  fill: none;
  opacity: .7;
  stroke: #ef5e8b;
  stroke-miterlimit: 10;
}

.cls-2, .cls-3 {
  fill: #ef5e8b;
}

.cls-3 {
  opacity: .15;
}

@media screen and (max-width: 769px) {
  svg {
    height: 80px;
    margin-top: 10px;
  }
}
</style>
