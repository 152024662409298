<template>
  <div class="main-trait">
    <div class="trait-detail-container dashboard-card">
      <button class="lab-btn lab-btn-back" @click="$router.push({ name: 'dashboard-traits'})"> 
        <feather-icon icon="ArrowLeftIcon" size="20"/>
        Back
      </button>
      <div class="traits-result-container ">
        <div class="trait-item hidden-mobile" v-if="traitData !== null">
          <div class="trait-item-left">
            <b-img
              :src="traitData.trait.traitFeature.iconUrl" height="40"  width="40"
            />
            <div class="ml-2">
              <div class="name">{{traitData.trait.traitFeature.name}}</div>
              <div class="description">{{traitData.trait.traitEffect.effect}}</div>
            </div>

          </div>
          <div class="trait-item-right">
            <div class="trait-rate-card"               
                  :class="{ 'trait-rate-low': traitData.trait.rate == 1, 
                          'trait-rate-intermediate': traitData.trait.rate == 2, 
                          'trait-rate-high': traitData.trait.rate == 3 }">
              {{traitData.trait.rateText}}
            </div>
          </div>
        </div>
        <div class="trait-item-mobile hidden-desktop" v-if="traitData !== null">
          <div class="trait-item-top">
            <b-img
              :src="traitData.trait.traitFeature.iconUrl" height="40"  width="40"
            />
            <div class="trait-rate-card" 
              :class="{ 'trait-rate-low': traitData.trait.rate == 1, 
                        'trait-rate-intermediate': traitData.trait.rate == 2, 
                        'trait-rate-high': traitData.trait.rate == 3 }">
              {{traitData.trait.rateText}}
            </div>
          </div>
          <div class="trait-item-bottom">
            <div>
              <div class="name">{{traitData.trait.traitFeature.name}}</div>
              <div class="description">{{traitData.trait.traitEffect.effect}}</div>
            </div>
          </div>
        </div>
        <hr />

        <div class="trait-description mb-2 mt-3" v-if="traitData !== null"> 
          <div class="title-header">
            <h4>
              Description
            </h4>
          </div>
          <hr />
          <div class="trait-info-body">
            <p>
              {{traitData.trait.traitFeature.description}}
            </p>
          </div>
        </div>

        <trait-detail-list v-if="traitData !== null" :traitData="traitData" :modernChart="modernChart"/>

        <trait-detail-survey v-if="traitData !== null" :traitData="traitData" />
      </div>
    </div>
  </div>

</template>

<script>
import TraitDetailList from './TraitDetailList.vue'
import TraitDetailSurvey from './TraitDetailSurvey.vue';

export default {
  components: {
    TraitDetailList,
    TraitDetailSurvey,
  },
  data() {
    return {
      traitData: null,
      modernChart: null,
    }
  },
  methods: {
    onGetTrait() {
      const params = {
        traitId: this.$router.currentRoute.params.id,
        traitIdList: this.$router.currentRoute.params.traitIds.split(',').map(Number),
      };

      this.$store.dispatch('traitsAndHealths/fetchTraitDetailData', params)
        .then(response => {
          if (response.statusCode === 200) {
            this.traitData = response.result;
            this.onCalculateTraitData();
            this.onChangeChartOptions(this.traitData.illustrativeCustomerAverage);
          }
        });
    },
    onCalculateTraitData() {
      this.traitData.traitDetailList.forEach(element => {
        element.frequencyList.forEach(item => {
          item.frequencyRatio = Math.round(item.frequencyRatio * 1000) / 1000;
        });
      });
    },
    onChangeChartOptions(value) {
      if (this.traitData) {
        this.modernChart = {
          series: [value],
          chartOptions: {
            colors: ['#EF5E8B'],
            series: [value],
            chart: {
              type: 'radialBar',
            },
            plotOptions: {
              radialBar: {
                size: 80,
                hollow: {
                  size: '70%',
                },
                track: {
                  background: '#ef5e8b33',
                },
                dataLabels: { 
                  name: {
                    show: false,
                  },
                  value: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                    color: this.$store.state.appConfig.layout.skin === 'light' ? '#EF5E8B' : '#FCFCFC',
                    fontWeight: 'bold',
                  },
                },
              },
            },
            labels: ['Cricket'],
          },
        };
      }
    },
    ddAlpha(color, opacity) {
      const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
      return color + _opacity.toString(16).toUpperCase();
    },
  },
  computed: {
    selectedTraitResult() {
      return this.$store.getters['orderResultTrait/getSelectedOrderResult'];
    },
  },
  watch: {
    selectedTraitResult(newValue, oldValue) {
      this.$router.push({ name: 'dashboard-traits' });
    },
  },
  created() {
    this.onGetTrait();
  },
}
</script>

<style lang="scss">
@import '../traits.scss';
</style>

<style lang="scss" scoped>
.trait-detail-container {
  padding: 30px;
}

.traits-result-container {
  width:100%;
  padding: 30px 30px;
}

.trait-description {
  .trait-info-body {
    padding: 1rem;
    p {
      color: var(--lab-stieglitz-silver);
      font-size: 0.8rem;
    }
  }
}

.dark-layout .trait-description .trait-info-body p {
    color: var(--lab-doctor);
}

.lab-btn-back {
  padding: 0.5rem 0.9rem 0.5rem 0.8rem;
  background-color: var(--lab-light-house);
  color: var(--lab-stieglitz-silver);
  font-size: 1rem;
  font-weight: bold;
}

.dark-layout .lab-btn-back {
  background-color: var(--lab-neverything);
  color: var(--lab-doctor);
}

@media screen and (max-width: 769px) { 
 .trait-detail-container  {
    padding: 15px;
 }
 .traits-result-container {
    padding: 30px;
 }
}
</style>
